import axios from "axios";

const API_URL = 'https://api.himomandbaby.kr:5000';

//const API_URL = 'http://localhost:5000';

const axiosInstance = axios.create({
  baseURL: API_URL,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use(function (config) {
  // 요청을 보내기 전에 수행할 작업
  const token = localStorage.getItem('access_token');
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
}, function (error) {
  // 오류 요청을 보내기 전에 수행할 작업
  return Promise.reject(error);
});


axiosInstance.interceptors.response.use(
  function (response) {
    // 응답을 받은 후 수행할 작업
    return response;
  },
  function (error) {
    // 응답 오류 처리
    if (error.response && error.response.status === 401) {
      // 401 Unauthorized 응답이 반환되면 로그아웃 처리
      localStorage.removeItem('access_token');
      // 로그인 페이지로 이동
      window.location.reload();

    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
