import { Suspense, lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import { RouteObject } from 'react-router';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';

import { useAuth } from 'src/AuthProvider'; // Assuming AuthProvider is in src directory

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Login
const LoginForm = Loader(lazy(() => import('src/components/Login/LoginForm')));


const User = Loader(lazy(() => import('src/content/applications/User')));
const AddUser = Loader(
  lazy(() => import('src/content/applications/User/AddUser'))
);

const AddNewUser = Loader(
  lazy(() => import('src/content/applications/User/AddNewUser'))
);
const UserList = Loader(
  lazy(() => import('src/content/applications/User/UserTitleInfoTable'))
);


const Notification = Loader(lazy(() => import('src/content/applications/Notification')));


const NotificationSchedule = Loader(
  lazy(() => import('src/content/applications/NotificationSchedule'))
);
const NotificationScheduleTable = Loader(
  lazy(() => import('src/content/applications/NotificationSchedule/NotificationScheduleTable'))
);
const NotificationScheduleDetail = Loader(
  lazy(() => import('src/content/applications/NotificationSchedule/NotificationScheduleDetail'))
);

const NotificationLog = Loader(
  lazy(() => import('src/content/applications/NotificationLog'))
);
const NotificationLogTable = Loader(
  lazy(() => import('src/content/applications/NotificationLog/NotificationLogTable'))
);


const MeditationMusic = Loader(lazy(() => import('src/content/applications/MeditationMusic')));
const AddMeditationMusic = Loader(
  lazy(() => import('src/content/applications/MeditationMusic/AddMeditationMusic'))
);
const MeditationMusicList = Loader(
  lazy(() => import('src/content/applications/MeditationMusic/MeditationMusicTitleInfoTable'))
);

const MeditationStory = Loader(lazy(() => import('src/content/applications/MeditationStory')));
const AddMeditationStory = Loader(
  lazy(() => import('src/content/applications/MeditationStory/AddMeditationStory'))
);
const MeditationStoryList = Loader(
  lazy(() => import('src/content/applications/MeditationStory/MeditationStoryTitleInfoTable'))
);

const Letter = Loader(lazy(() => import('src/content/applications/Letter')));
const LetterUserList =  Loader(lazy(() => import('src/content/applications/Letter/LetterUserTable')));
const AddLetter = Loader(
  lazy(() => import('src/content/applications/Letter/AddLetter'))
);
const LetterList = Loader(
  lazy(() => import('src/content/applications/Letter/LetterTitleInfoTable'))
);


const Information = Loader(lazy(() => import('src/content/applications/Information')));
const AddInformation = Loader(
  lazy(() => import('src/content/applications/Information/AddInformation'))
);
const InformationList = Loader(
  lazy(() => import('src/content/applications/Information/InformationTitleInfoTable'))
);


const Question = Loader(lazy(() => import('src/content/applications/Question')));
const AddQuestion = Loader(
  lazy(() => import('src/content/applications/Question/AddQuestion'))
);
const QuestionList = Loader(
  lazy(() => import('src/content/applications/Question/QuestionTitleInfoTable'))
);


const Inquiry = Loader(lazy(() => import('src/content/applications/Inquiry')));

const InquiryList = Loader(
  lazy(() => import('src/content/applications/Inquiry/InquiryTitleInfoTable'))
);



// Status
const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('src/content/pages/Status/Status500'))
);
const StatusComingSoon = Loader(
  lazy(() => import('src/content/pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('src/content/pages/Status/Maintenance'))
);

const Privacy = Loader(
  lazy(() => import('src/content/pages/Status/Privacy'))
);

const ProtectedSidebarLayout: React.FC = () => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? <SidebarLayout /> : <Navigate replace to="/login" />;
};

const routes: RouteObject[] = [
  {
    path: 'login',
    element: <LoginForm />
  },
  {
    path: 'privacy',
    element: <Privacy/>
  },
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Navigate replace to="management/user" />
      },
      {
        path: 'status',
        children: [
          {
            path: '',
            element: <Navigate to="404" replace />
          },
          {
            path: '404',
            element: <Status404 />
          },
          {
            path: '500',
            element: <Status500 />
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />
          },
          {
            path: 'coming-soon',
            element: <StatusComingSoon />
          }
        ]
      },
      {
        path: '*',
        element: <Status404 />
      }
    ]
  },
  {
    path: 'management',
    element: <ProtectedSidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="user" replace />
      },

      {
        path: 'user',
        element:<User/>,
        children: [
          { path: '', element: <UserList/> },
          { path: 'add-user', element: <AddUser /> },
          { path: 'add-new-user', element: <AddNewUser /> },
          { path: ':id', element: <AddUser /> } 

        ]
      },
      {
        path: 'notification',
        element:<Notification/>
      },

      {
        path: 'notification-schedules',
        element:<NotificationSchedule/>,
        children: [
          { path: '', element: <NotificationScheduleTable/> },
          { path: 'add', element: <NotificationScheduleDetail /> },
          { path: ':id', element: <NotificationScheduleDetail /> } 

        ]
      },

      {
        path: 'notification-logs',
        element:<NotificationLog/>,
        children: [
          { path: '', element: <NotificationLogTable/> }
        ]
      },

      {
        path: 'meditation-music',
        element:<MeditationMusic/>,
        children: [
          { path: '', element: <MeditationMusicList/> },
          { path: 'add-meditation-music', element: <AddMeditationMusic /> },
          { path: ':id', element: <AddMeditationMusic /> } 

        ]
      },

      {
        path: 'meditation-story',
        element:<MeditationStory/>,
        children: [
          { path: '', element: <MeditationStoryList/> },
          { path: 'add-meditation-story', element: <AddMeditationStory /> },
          { path: ':id', element: <AddMeditationStory /> } 

        ]
      },

      {
        path: 'letter',
        element:<Letter/>,
        children: [
          { path: '', element: <LetterUserList/> },
          { path: 'add-letter', element: <AddLetter /> },
          { path: 'user/:id', element: <LetterList /> }, 
          { path: 'letter/:id', element: <AddLetter /> }

        ]
      },
      
      {
        path: 'information',
        element:<Information/>,
        children: [
          { path: '', element: <InformationList/> },
          { path: 'add-information', element: <AddInformation /> },
          { path: ':id', element: <AddInformation /> } 

        ]
      },

      {
        path: 'question',
        element:<Question/>,
        children: [
          { path: '', element: <QuestionList/> },
          { path: 'add-question', element: <AddQuestion /> },
          { path: ':id', element: <AddQuestion /> } 

        ]
      },

      {
        path: 'inquiry',
        element:<Inquiry/>,
        children: [
          { path: '', element: <InquiryList/> }

        ]
      }
    ]
  }
];

export default routes;
