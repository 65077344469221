import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import 'nprogress/nprogress.css';
import App from 'src/App';
import { SidebarProvider } from 'src/contexts/SidebarContext';
import * as serviceWorker from 'src/serviceWorker';
import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: "AIzaSyCXpK5zIIMxNSYHedo-tPzWitcjAGXG6Yw",
  authDomain: "himom-f5fa8.firebaseapp.com",
  projectId: "himom-f5fa8",
  storageBucket: "himom-f5fa8.appspot.com",
  messagingSenderId: "800187759134",
  appId: "1:800187759134:web:27cd5f07f9962019530c0f",
  measurementId: "G-FW2PW50TXN"
};

initializeApp(firebaseConfig);

ReactDOM.render(
  <HelmetProvider>
    <SidebarProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </SidebarProvider>
  </HelmetProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
